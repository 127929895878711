import React, { useEffect, createRef } from "react"
import LayoutTeam from "../components/layoutteam"
import Navigation from "../components/navigation"
import { useStaticQuery, graphql, Link, navigate } from "gatsby"
import "../styles/hybrid.scss"
import SEOHeader from "../components/seo-header"
import teamLogo from "../img/TeamLogo.svg"
import lottie from "lottie-web"
import SafeSpace from "../img/safety-badge.jpg"
import animation from "../animations/newhomeanimation.json"
import "../styles/coworking.scss"

import { Typeahead } from "react-bootstrap-typeahead"
const BrandPage = props => {
  const { brand } = props.pageContext
  const cities = props.data
  const countSider = props.data.cityCount
  const cityNameCount = props.data.cityCount.cityNameCount
  const spaceAddressCount = props.data.cityCount.spaceAddressCount
  const CityImage = props => {
    const imageBucket = {
      coworkingPhotos: [],
      meetingRoomPhotos: [],
    }
    const getPhotoUrlByCity = cityName => {
      const photoObject = imageBucket.meetingRoomPhotos.find(
        photo => photo.city.toLowerCase() === cityName.toLowerCase()
      )

      return photoObject ? photoObject.photo : null
    }

    // Example usage:

    const photoUrl = getPhotoUrlByCity(
      props.name.toLowerCase().replace(" ", "-")
    )
    const localitynew = cities.allListings.edges.filter(
      list =>
        list.node.spaceCity === props.name &&
        list.node.spaceType === props.spaceType
    )

    if (localitynew.length > 0) {
      return (
        <div className="space_img" title={props.name}>
          <img
            src={localitynew[0].node.spaceImage.replace(
              "https://firebasestorage.googleapis.com/v0/b/gofloaters.appspot.com/o",
              "https://cdn.app.gofloaters.com"
            )}
            alt={props.name}
            title={props.name}
          />
        </div>
      )
    }
    return ""
  }

  const coworkingCount = countSider.edges.filter(
    list => list.node.spaceType === "Shared Workspace"
  )
  const meetingCount = countSider.edges.filter(
    list => list.node.spaceType === "Conference Room"
  )

  return (
    <div>
      <SEOHeader title={brand + "- GoFloaters"} description={brand}></SEOHeader>

      <LayoutTeam>
        <div className="meetingSpaces">
          <div>
            <Navigation />
            <div className="container">
              <div className="row">
                <div className="col-md-2"></div>
                <div className="col-md-8">
                  <h1
                    style={{
                      fontSize: "3em",
                      textAlign: "center",
                      fontWeight: "bold",
                      padding: "35px 0 20px ",
                    }}
                  >
                    {brand}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row  ">
            <IntroContent brand={brand} />
            <div className="col-md-12">
              <div className="row">
                <br></br>
                <div className="col-md-3 TeamsSellingPoint">
                  <div className="points">
                    <div className="row">
                      <div className="col-md-3 col-2 text-center">
                        <img
                          data-src="https://assets.gofloaters.com/brands/city.svg"
                          alt={cityNameCount.length + "Number of cities"}
                          className="lazyload"
                        ></img>
                      </div>
                      <div className="col-md-9 col-10">
                        <h4>{cityNameCount.length}</h4>
                        <p>Number of cities</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 TeamsSellingPoint">
                  <div className="points">
                    <div className="row">
                      <div className="col-md-3 col-2 text-center">
                        <img
                          data-src="https://assets.gofloaters.com/brands/location.svg"
                          alt={countSider.totalCount}
                          className="lazyload"
                        ></img>
                      </div>
                      <div className="col-md-9 col-10">
                        <h4> {countSider.totalCount}</h4>
                        <p>Number of spaces</p>
                      </div>
                    </div>
                  </div>
                </div>
                {coworkingCount.length ? (
                  <div className="col-md-3 TeamsSellingPoint">
                    <div className="points">
                      <div className="row">
                        <div className="col-md-3 col-2 text-center">
                          <img
                            data-src="https://assets.gofloaters.com/brands/coworking.svg"
                            alt={
                              coworkingCount.length +
                              "Number of coworking day pass spaces"
                            }
                            className="lazyload"
                          ></img>
                        </div>
                        <div className="col-md-9 col-10">
                          <h4>{coworkingCount.length}</h4>
                          <p>Number of coworking day pass spaces</p>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <div className="col-md-3 TeamsSellingPoint">
                  <div className="points">
                    <div className="row">
                      <div className="col-md-3 col-2 text-center">
                        <img
                          data-src="https://assets.gofloaters.com/brands/meeting.svg"
                          alt={meetingCount.length + "Number of meeting rooms"}
                          className="lazyload"
                        ></img>
                      </div>
                      <div className="col-md-9 col-10">
                        <h4>{meetingCount.length}</h4>
                        <p>Number of meeting rooms</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2
                style={{
                  padding: "20px 0 0px",
                  fontWeight: "bold",
                  color: "#303030",
                  lineHeight: "1",
                  fontSize: "32px",
                }}
              >
                Meeting Spaces
              </h2>
            </div>
          </div>
          <div className="row">
            {cities.allCities.edges.map(city => {
              const localitynew = cities.allListings.edges.filter(
                list =>
                  list.node.spaceCity === city.node.city &&
                  list.node.spaceType === "Conference Room"
              )
              if (localitynew.length > 0)
                return (
                  <div className="col-md-4 col-6">
                    <Link
                      to={
                        "/meeting-spaces/" +
                        city.node.slug +
                        "/all?operatorName=" +
                        brand
                      }
                    >
                      <CityImage
                        name={city.node.city}
                        spaceType="Conference Room"
                      ></CityImage>
                      <h3 className="brandSpaceTitle">{city.node.city}</h3>
                      <h4 className="brandSubTitle">
                        {localitynew.length} options
                      </h4>
                      <br />
                    </Link>
                  </div>
                )
            })}
          </div>
        </div>

        {coworkingCount.length ? (
          <div className="container">
            <br></br>
            <div className="row">
              <div className="col-md-12">
                <h2
                  style={{
                    padding: "20px 0 0px",
                    fontWeight: "bold",
                    color: "#303030",
                    lineHeight: "1",
                    fontSize: "32px",
                  }}
                >
                  Coworking Spaces
                </h2>
              </div>
            </div>
            <div className="row">
              {cities.allCities.edges.map(city => {
                const localitynew = cities.allListings.edges.filter(
                  list =>
                    list.node.spaceCity === city.node.city &&
                    list.node.spaceType === "Shared Workspace" &&
                    list.node.dayPassAvailable === true
                )
                if (localitynew.length > 0)
                  return (
                    <div className="col-md-4 col-6">
                      <Link
                        to={
                          "/coworking-spaces/" +
                          city.node.slug +
                          "/all?operatorName=" +
                          brand
                        }
                      >
                        <CityImage
                          name={city.node.city}
                          spaceType="Shared Workspace"
                        ></CityImage>
                        <h3 className="brandSpaceTitle">{city.node.city}</h3>
                        <h4 className="brandSubTitle">
                          {localitynew.length} options
                        </h4>
                        <br />
                      </Link>
                    </div>
                  )
              })}
            </div>
          </div>
        ) : (
          ""
        )}

        {coworkingCount.length ? (
          <div className="container">
            <br></br>
            <div className="row">
              <div className="col-md-12">
                <h2
                  style={{
                    padding: "20px 0 0px",
                    fontWeight: "bold",
                    color: "#303030",
                    lineHeight: "1",
                    fontSize: "32px",
                  }}
                >
                  Office Spaces
                </h2>
              </div>
            </div>
            <div className="row">
              {cities.allCities.edges.map(city => {
                const localitynew = cities.allListings.edges.filter(
                  list =>
                    list.node.spaceCity === city.node.city &&
                    list.node.spaceType === "Shared Workspace" &&
                    list.node.monthPassAvailable === true
                )
                if (localitynew.length > 0)
                  return (
                    <div className="col-md-4 col-6">
                      <Link
                        to={
                          "/office-spaces/" +
                          city.node.slug +
                          "/all?operatorName=" +
                          brand
                        }
                      >
                        <CityImage
                          name={city.node.city}
                          spaceType="Shared Workspace"
                        ></CityImage>
                        <h3 className="brandSpaceTitle">{city.node.city}</h3>
                        <h4 className="brandSubTitle">
                          {localitynew.length} options
                        </h4>
                        <br />
                      </Link>
                    </div>
                  )
              })}
            </div>
          </div>
        ) : (
          ""
        )}
        <div className="container">
          <div className="row">
            <BottomContent brand={brand} />
          </div>
        </div>
      </LayoutTeam>
    </div>
  )
}
export default BrandPage
export const query = graphql`
  query BrandPageQuery($brand: String!) {
    allCities {
      totalCount
      edges {
        node {
          city
          cityPriority
          displayCity
          slug
        }
      }
    }
    cityCount: allListings(
      filter: { operatorName: { eq: $brand }, slug: { ne: "" } }
      sort: { fields: isFeatured, order: DESC }
    ) {
      totalCount
      edges {
        node {
          spaceCity
          OriginalName
          spaceAddress
          spaceType
          dayPassAvailable
        }
      }
      cityNameCount: distinct(field: spaceCity)
      spaceAddressCount: distinct(field: spaceAddress)
    }
    allListings(
      filter: { operatorName: { eq: $brand }, slug: { ne: "" } }
      sort: { fields: isFeatured, order: DESC }
    ) {
      totalCount
      edges {
        node {
          monthPassAvailable
          dayPassAvailable
          hourPassAvailable
          officeSpaceType
          purposesList
          spaceAddress
          spaceGFName
          OriginalName
          spaceCity
          spaceId
          spaceImage
          spaceTitle
          spaceDesc
          spaceType
          subType
          priceDay
          priceHr
          priceMonth
          spaceDisplayName
          Facility
          slug
          hasCovidSafeBadge
          online
          Rating
          pincode
          bookingcount
          isFeatured
          priceperdayPreDiscount
          priceperhrPreDiscount
        }
      }
    }
  }
`
export function IntroContent(props) {
  switch (props.brand) {
    case "WeWork":
      return (
        <div className="col-md-12">
          <p style={{ textAlign: "justify" }}>
            Introduction Since its founding in 2016, WeWork India has led the
            way in transforming workspaces all throughout the nation. It now has
            more than 50 outlets spread throughout eight major cities, ranging
            from the tech-heavy Bangalore and Hyderabad to the busy financial
            hubs of Delhi and Mumbai. WeWork India's explosive growth can be
            ascribed to its emphasis on developing a dynamic workplace
            environment. They combine a distinctive selection of flexible day
            passes that satisfy the demands of mobile professionals with chic
            conference spaces that are ideal for holding memorable presentations
            or brainstorming sessions. WeWork India, however, is more than just
            physical locations. Their distinctive selling point is that they
            support a cooperative network of freelancers, established companies,
            and entrepreneurs so that they can grow and innovate together..
          </p>
          <br />
        </div>
      )
    case "Cowrks":
      return (
        <div className="col-md-12">
          <p style={{ textAlign: "justify" }}>
            Offering a dynamic network of stimulating and collaborative
            coworking spaces throughout India's main cities, including
            Bangalore, Mumbai, Delhi NCR, Hyderabad, and Chennai, Cowrks India
            has been revolutionizing the workspace scene since 2016. Their
            mission was to provide flexible workspace options to organizations
            and individuals, and that was where their journey started. With more
            than 16 gorgeous centers in ideal settings, Cowrks India is now
            known for its dedication to supporting your success, convenience,
            and sense of community. This platform offers a comprehensive range
            of possibilities to customize your ideal workplace experience, from
            modern meeting rooms great for brainstorming sessions to day passes
            ideal for remote work. Allow Cowrks India to serve as your launching
            pad into a productive and connected future.
          </p>
          <br />
        </div>
      )
    case "AWFIS":
      return (
        <div className="col-md-12">
          <p style={{ textAlign: "justify" }}>
            Since its founding in 2015, AWFIS India has expanded from a single
            co-working space to become the nation's foremost provider of
            flexible workspace options. Presently, AWFIS provides more
            possibilities than in the past; day tickets and conference rooms are
            offered in 16 locations around India. Their emphasis on serving a
            varied customer, ranging from startups and independent contractors
            to major corporations, has contributed to their increase in
            popularity. The ability to offer all the necessities for an
            efficient workday combined with the freedom to select the workplace
            that best meets your needs is what makes AWFIS special.
          </p>
          <br />
        </div>
      )
    case "Innov8":
      return (
        <div className="col-md-12">
          <p style={{ textAlign: "justify" }}>
            Pioneered by Y-Combinator-backed visionaries, Innov8 India has been
            revolutionizing the co-working landscape since its inception. From
            its beginnings, Innov8 has transformed real estate into vibrant hubs
            for creativity and collaboration, boasting a presence in 10 key
            cities across India. Their commitment to fostering a dynamic work
            environment and offering premium, beautifully designed spaces has
            catapulted them to popularity, attracting a diverse clientele
            including digital nomads, entrepreneurs, and established businesses.
            Innov8's unique selling proposition lies in its focus on
            customization and value for money, providing flexible workspace
            solutions and fostering a strong sense of community for its members.
          </p>
          <br />
        </div>
      )
    case "91Springboard":
      return (
        <div className="col-md-12">
          <p style={{ textAlign: "justify" }}>
            Since its founding in 2012, 91Springboard has revolutionized the
            co-working space industry in India, changing the game for
            professionals looking for a dynamic and efficient workspace.
            91Springboard, which was founded by visionary individuals who valued
            creating a vibrant community over merely having physical locations,
            has an amazing network of 28 hubs spread around 8 major cities,
            serving over 17,000 members. Leading the way in India's co-working
            movement, they focus on building relationships and offering an
            extensive range of tools, including flexible workplace choices like
            conference rooms and day passes.
          </p>
          <br />
        </div>
      )
    case "MyBranch":
      return (
        <div className="col-md-12">
          <p style={{ textAlign: "justify" }}>
            MyBranch is a leading provider of office space solutions in India,
            specializing in co-working spaces and managed offices. Founded in
            2016, MyBranch offers a range of services, including virtual
            offices, meeting rooms, sales desk, SME workplace, and managed
            offices. This enables businesses of all sizes to easily set up local
            office spaces in preferred locations and save time.
          </p>
          <br />
        </div>
      )
    default:
      return <div className="col-md-12"></div>
  }
}

export function BottomContent(props) {
  switch (props.brand) {
    case "WeWork":
      return (
        <div className="col-md-12">
          <p>
            WeWork India offers a dynamic network of coworking spaces and
            private offices across eight major cities - Bangalore, Chennai,
            Delhi, Gurgaon, Hyderabad, Mumbai, Noida, and Pune. Whether you're a
            freelancer seeking a casual touchdown spot or an established company
            needing a full-fledged office setup, WeWork has the ideal solution
            for you.
          </p>

          <ul>
            <li>
              Bangalore: In Bangalore, WeWork boasts over 20 locations spread
              across prominent areas like the Central Business District (CBD),
              Domlur, Hebbal, Koramangala, and Bellandur. These vibrant spaces
              are housed in high-rise buildings with stunning views, offering
              excellent connectivity via the Namma Metro.
            </li>
            <li>
              Chennai: For professionals in Chennai, WeWork provides a
              well-equipped space at Olympia Cyberspace in Guindy, known for its
              contemporary design and proximity to the IT corridor. The location
              is easily accessible by both the Chennai Metro Rail and MTC buses.
            </li>
            <li>
              Delhi: WeWork's sole Delhi location in Iffco Chowk provides a
              prestigious address in the heart of the capital. This
              well-connected space is close to the Rajiv Chowk Metro Station,
              making it convenient for those commuting by public transport.
            </li>
            <li>
              Gurgaon: Gurgaon offers a diverse range of WeWork options across
              eight buildings in prominent areas like Cyber Hub, Golf Course
              Road, and DLF Cyber City. These business hubs are well-served by
              the Gurgaon Rapid Metro and cabs.
            </li>
            <li>
              Hyderabad: Hyderabad's four WeWork spaces are strategically
              located in commercial districts like Gachibowli, Jubilee Hills,
              and HITEC City. These contemporary workspaces offer easy access
              via the Hyderabad Metro Rail.
            </li>
            <li>
              Mumbai: In the fast-paced city of Mumbai, WeWork features 12
              locations spread across prominent areas like Bandra Kurla Complex
              (BKC), Lower Parel, and Nariman Point. These well-connected spaces
              are easily accessible by the Mumbai Metro and local trains.
            </li>
            <li>
              Noida: WeWork Noida offers a workspace solution at Sector 62, a
              prime location for businesses. The space boasts excellent
              connectivity via the Noida Metro.
            </li>
            <li>
              Pune: Pune's five WeWork locations cater to professionals in areas
              like Hinjewadi, Kharadi, and Viman Nagar. These innovative
              workspaces are well-connected by the Pune Metro and PMPML buses.
            </li>
          </ul>
          <br />
          <p>
            WeWork and Cowrks India are both major players in the Indian
            coworking space, but they cater to slightly different preferences.
            WeWork, with its global presence and established brand, might be
            ideal for companies seeking a dynamic, international network for
            potential collaboration. If a strong community focus is a priority,
            WeWork could also be a good fit. However, Cowrks India might be a
            better choice for those seeking a premium experience with a focus on
            Indian entrepreneurship and a potentially more established local
            presence..
          </p>
          <br />
          <b>Why Book with GoFloaters?</b>
          <p>
            There are many advantages to using GoFloaters to reserve your WeWork
            India office. GoFloaters ensures that you will receive the best deal
            possible, giving the appearance of a reasonably priced workspace.
            The technology streamlines the entire process by making booking,
            discovery, and safe online payment simple. You can use GoFloaters to
            quickly check the availability of the space you want without having
            to waste time calling or emailing potential tenants. So, save
            yourself the trouble and reserve your ideal WeWork India workplace
            with GoFloaters right now!
          </p>
          <br />
        </div>
      )
    case "Cowrks":
      return (
        <div className="col-md-12">
          <p>
            Coworks India offers a dynamic network of collaborative workspaces
            strategically located across major cities. Whether you're a seasoned
            entrepreneur, a budding freelancer, or a remote team seeking a
            vibrant work environment, Coworks has the perfect solution for you.
          </p>
          <ul>
            <li>
              <p>Bangalore</p>
            </li>
            <ul>
              <li>
                <p>
                  Indiranagar: Situated in the heart of Indiranagar's buzzing
                  commercial area, this center boasts a modern design and ample
                  natural light. Perfect for those seeking a trendy and
                  inspiring workspace, it's well-connected by metro and buses.
                </p>
              </li>
              <li>
                <p>
                  Koramangala: Strategically located near Sony Signal,
                  Koramangala offers a lively atmosphere with on-site cafes and
                  relaxation areas. Easily accessible via the metro and buses.
                </p>
              </li>
              <li>
                <p>
                  HSR Layout: Escape the hustle and bustle in HSR Layout's
                  serene locality. This center provides a focused and productive
                  environment, just a short commute from major IT hubs through
                  cabs and autos.
                </p>
              </li>
            </ul>
            <li>
              <p>Mumbai:</p>
            </li>
            <ul>
              <li>
                <p>
                  Bandra Kurla Complex (BKC): In the heart of Mumbai's financial
                  district, BKC offers unparalleled connectivity and a
                  prestigious address. Metro and local trains provide easy
                  access.
                </p>
              </li>
              <li>
                <p>
                  Andheri (East): Situated off the bustling LBS Marg, Andheri
                  (East) is a cost-effective option with excellent Wi-Fi and
                  meeting room facilities. Well-connected by local trains and
                  buses.
                </p>
              </li>
            </ul>
            <li>
              <p>Gurugram:</p>
            </li>
            <ul>
              <li>
                <p>
                  Golf Course Road: Impress clients with a professional address
                  in Gurugram's upscale Golf Course Road. This center provides
                  top-notch amenities and is easily accessible by cabs and
                  autos.
                </p>
              </li>
            </ul>
            <li>
              <p>Hyderabad:</p>
            </li>
            <ul>
              <li>
                <p>
                  Madhapur: Located in the heart of Hyderabad's IT corridor,
                  Madhapur offers a collaborative environment with a strong
                  sense of community. Metro and buses provide easy access.
                </p>
              </li>
            </ul>
            <li>
              <p>Pune</p>
            </li>
            <ul>
              <li>
                <p>
                  Hinjewadi: Situated in the heart of Pune's IT hub, Hinjewadi
                  offers a dynamic workspace with modern amenities.
                  Well-connected by cabs and autos.
                </p>
              </li>
            </ul>
          </ul>
          <br />
          <p>
            Both WeWork India and Cowrks are major players in the Indian
            coworking space, but they cater to slightly different audiences.
            WeWork, with its global presence, might appeal to established
            businesses seeking a familiar and reliable brand.
          </p>
          <p>
            However, Cowrks could be a better fit for those looking for a more
            local touch. Backed by the RMZ Group, Cowrks emphasizes building a
            network of Indian entrepreneurs and offers a mix of standard and
            customized workspace solutions, potentially providing a more
            personalized experience.
          </p>
          <br />
          <b>Why Book with GoFloaters?</b>
          <p>
            When you rent a meeting room, conference room, day pass, coworking
            space, or office with Coworks India, GoFloaters offers unparalleled
            perks. With an easy-to-use interface, our program expedites your
            search and guarantees the lowest price. With just a few clicks, you
            may swiftly reserve and pay for your seat without making any calls
            or sending any emails. It's that easy to secure your dream
            workstation in minutes by verifying the real-time space
            availability! Use GoFloaters to get rid of the hassle and have an
            impeccable working relationship with Coworks India.
          </p>
        </div>
      )
    case "AWFIS":
      return (
        <div className="col-md-12">
          <p>
            Awfis India offers a dynamic network of coworking spaces, private
            offices, meeting rooms, and conference rooms across major cities in
            India.&nbsp; Whether you're a freelancer seeking a focused
            environment, a startup looking for a collaborative hub, or an
            established company needing a professional meeting space, Awfis has
            the ideal solution for you.
          </p>

          <ul>
            <li>
              <p>Chennai</p>
            </li>
            <ul>
              <li>
                <p>
                  Awfis OMR 1: Located in the heart of Chennai's IT corridor,
                  OMR, this center is just off Rajiv Gandhi Salai, near the
                  Sipcot IT Park. This vibrant space boasts high-speed Wi-Fi,
                  meeting rooms, private cabins, and a relaxed cafe area,
                  perfect for networking. It's easily accessible by metro and
                  well-connected by road
                </p>
              </li>
              <li>
                <p>
                  Awfis Spero Primus: Situated in the bustling Guindy
                  neighbourhood, this center is close to the DLF Cyber Park and
                  the Chennai Airport. This premium facility offers private
                  offices, soundproof meeting rooms, and a business lounge,
                  ideal for a productive work environment. The location is
                  well-served by public transport options including the metro
                  and buses.
                </p>
              </li>
              <li>
                <p>
                  Awfis Gemini Circle: Nestled in the T Nagar area, this center
                  is close to the iconic Pondy Bazaar and offers stunning views
                  of the city. This contemporary space features open coworking
                  areas, private cabins, meeting rooms, and a fully equipped
                  pantry. With metro connectivity and ample parking, it's a
                  convenient location for professionals.
                </p>
              </li>
            </ul>
            <li>
              <p>Bangalore:</p>
            </li>
            <ul>
              <li>
                <p>
                  Awfis Brigade Road: Situated on the iconic Brigade Road, this
                  center is a stone's throw away from MG Road and the Bangalore
                  Metro. This vibrant space offers a dynamic mix of coworking
                  areas, private offices, meeting rooms, and a recreational
                  zone, perfect for fostering creativity and collaboration.
                </p>
              </li>
              <li>
                <p>
                  Awfis Koramangala: Nestled in the heart of Bangalore's startup
                  hub, Koramangala, this center is close to major tech companies
                  and co-working spaces. This sleek facility provides private
                  offices, soundproof meeting rooms, and a well-equipped
                  business lounge, ideal for a professional work environment.
                  It's well-connected by buses and cabs.{" "}
                </p>
              </li>
              <li>
                <p>
                  Awfis Whitefield: Located in the bustling IT hub of
                  Whitefield, this center is close to major tech parks and
                  multinational companies. This expansive space offers a variety
                  of options, from open coworking areas and private cabins to
                  spacious meeting rooms and conference halls. The location is
                  easily accessible by metro and well-served by cabs.
                </p>
              </li>
            </ul>
            <li>
              <p>Hyderabad</p>
            </li>
            <ul>
              <li>
                <p>
                  Awfis Jubilee Hills: Nestled in the upscale Jubilee Hills
                  area, this center is a short distance from the IT hub of
                  Gachibowli. This premium facility offers private offices,
                  soundproof meeting rooms, and a business concierge service,
                  ideal for a prestigious work address. It's conveniently
                  located with easy access to cabs and autos.
                </p>
              </li>
              <li>
                <p>
                  Awfis Madhapur: Situated in the heart of Hyderabad's IT
                  district, Madhapur, this center is surrounded by tech
                  companies and startups. This vibrant space features open
                  coworking areas, private cabins, meeting rooms, and a
                  relaxation zone, perfect for a dynamic work environment. The
                  location is well-connected by buses and cabs.
                </p>
              </li>
            </ul>
            <li>
              <p>Delhi &amp; NCR</p>
            </li>
            <ul>
              <li>
                <p>
                  Awfis Nehru Place: Strategically located in the heart of Nehru
                  Place, a major business district in Delhi, this center offers
                  a professional and convenient workspace solution. This
                  contemporary space features private offices, meeting rooms,
                  and a business lounge, catering to various business needs. The
                  location is well-connected by metro and buses.
                </p>
              </li>
              <li>
                <p>
                  Awfis Gurgaon Sector 58: Situated in the heart of Gurgaon's
                  business district, Sector 58, this center is close to major
                  corporates and MNCs. This expansive space offers a variety of
                  options, from open coworking areas and private cabins to
                  spacious meeting rooms and conference halls. The location
                  boasts excellent connectivity through the Delhi Metro and
                  cabs.
                </p>
              </li>
              <li>
                <p>
                  Awfis Noida Sector 62: Nestled in the heart of Noida's IT hub,
                  Sector 62, this center is surrounded by tech companies and
                  startups. This vibrant space features open coworking areas,
                  private cabins, meeting rooms, and a relaxation zone, perfect
                  for a dynamic work environment. The location is well-connected
                  by the Noida Metro and cabs.
                </p>
              </li>
            </ul>
          </ul>
          <br />
          <ul>
            <li>
              <p>Mumbai </p>
            </li>
            <ul>
              <li>
                <p>
                  Awfis BKC: Located in the prestigious Bandra Kurla Complex
                  (BKC), Mumbai's central business district, this center offers
                  a world-class work environment. This premium facility boasts
                  private offices, state-of-the-art meeting rooms, and a
                  business concierge service, ideal for companies seeking an
                  impressive corporate address. It's easily accessible by cabs
                  and taxis.
                </p>
              </li>
              <li>
                <p>
                  Awfis Lower Parel: Situated in the heart of Lower Parel,
                  Mumbai's commercial hub, this center is surrounded by
                  financial institutions and multinational companies. This
                  vibrant space features open
                </p>
              </li>
            </ul>
          </ul>
          <br />
          <p>
            Awfis India has a growing presence across India, including key
            cities like Bangalore, Hyderabad, Delhi, Gurugram, Mumbai, Pune, and
            Noida. Each location offers a unique blend of amenities and a
            strategic position, ensuring there's an Awfis space perfectly suited
            for your needs.
          </p>
          <br />
          <p>
            <strong>Why Book with GoFloaters?</strong>
          </p>
          <p>
            Booking your Awfis space through GoFloaters offers a multitude of
            benefits. GoFloaters guarantees you the lowest price available,
            making your workspace solution even more economical.&nbsp; Their
            user-friendly platform allows for easy discovery of Awfis locations,
            real-time availability checks, and a seamless booking and payment
            process.&nbsp; You can secure your ideal workspace in minutes,
            without the need for calls or emails.
          </p>
          <br />
        </div>
      )
    case "Innov8":
      return (
        <div className="col-md-12">
          <p>
            Innov8 India provides a vibrant network of inspiring coworking
            spaces strategically located across Chennai, Bangalore, and
            Hyderabad.&nbsp; We offer a dynamic work environment that fosters
            collaboration and growth for individuals, startups, and enterprises.
          </p>

          <ul>
            <li>
              <p>Chennai</p>
            </li>
            <ul>
              <li>
                <p>
                  Central Business District (CBD): Located in the heart of
                  Chennai's financial district, our CBD space boasts stunning
                  views of the cityscape and is a short walk from Fort Station
                  and Central Metro Station.
                </p>
              </li>
              <li>
                <p>
                  OMR (Old Mahabalipuram Road): Situated on the IT corridor, our
                  OMR location is ideal for tech startups and businesses. It
                  offers easy access to SIPCOT IT Park and is well-connected by
                  buses and cabs.
                </p>
              </li>
              <li>
                <p>
                  T Nagar: Nestled in the bustling T Nagar area, this space is
                  perfect for those seeking a vibrant atmosphere. It's close to
                  Thyagaraya Nagar bus stand and provides a refreshing escape
                  from the traditional office setup.
                </p>
              </li>
            </ul>
            <li>
              <p>Bangalore:</p>
            </li>
            <ul>
              <li>
                <p>
                  Indiranagar: Our Indira Nagar space is a stone's throw away
                  from trendy cafes and restaurants, making it ideal for
                  networking and brainstorming sessions. It's easily accessible
                  via the Indiranagar Metro Station.
                </p>
              </li>
              <li>
                <p>
                  Koramangala: Located in the heart of Bangalore's startup hub,
                  Koramangala, this space provides a collaborative environment
                  for entrepreneurs and freelancers.&nbsp; It's well-connected
                  by the Intermediate Ring Road and metro.
                </p>
              </li>
              <li>
                <p>
                  HSR Layout: Our HSR Layout space offers a tranquil work
                  environment away from the city center.&nbsp; It's perfect for
                  focused work sessions and is easily accessible by buses and
                  cabs.
                </p>
              </li>
            </ul>
            <li>
              <p>Hyderabad:</p>
            </li>
            <ul>
              <li>
                <p>
                  Banjara Hills: Situated in the upscale Banjara Hills area,
                  this space provides a sophisticated work environment for
                  businesses and professionals.&nbsp; It's close to major
                  business districts and is well-connected by public transport.
                </p>
              </li>
              <li>
                <p>
                  Madhapur: Located in the heart of Hyderabad's IT hub,
                  Madhapur, this space is ideal for tech companies and
                  startups.&nbsp; It offers easy access to major IT parks and is
                  well-connected by the Hyderabad Metro Rail.
                </p>
              </li>
              <li>
                <p>
                  Gachibowli: Nestled in Gachibowli, a prominent financial and
                  IT district, this space provides a dynamic environment for
                  businesses of all sizes.&nbsp; It's close to major business
                  parks and is easily accessible by public transport.
                </p>
              </li>
            </ul>
          </ul>
          <br />
          <p>
            <strong>Why Book with GoFloaters?</strong>
          </p>
          <p>
            Booking your workspace at Innov8 India through GoFloaters offers a
            multitude of benefits.&nbsp; Our platform guarantees you the lowest
            possible price for your chosen space.&nbsp; Discover, book, and pay
            for your workspace seamlessly through our user-friendly website,
            eliminating the need for calls or emails.&nbsp; Check instant
            availability for your desired space and get started on your
            innovative journey right away!
          </p>
        </div>
      )
    case "91Springboard":
      return (
        <div className="col-md-12">
          <p>
            <strong>91Springboard India: </strong> Your Gateway to a Thriving
            Work Ecosystem
          </p>
          <p>
            91Springboard boasts a wide presence across eight major cities in
            India, offering a plethora of options for your workspace needs.
            Whether you're a freelancer seeking a casual day pass or an
            established company looking for a permanent office setup,
            91Springboard has the perfect solution.
          </p>
          <ul>
            <li>
              <p>
                Delhi: Find vibrant coworking spaces in well-connected
                localities like Mohan Estate, Nehru Place, and Jhandewalan.
                These centers are a stone's throw away from metro stations,
                ensuring seamless commutes.
              </p>
            </li>
            <li>
              <p>
                Gurugram: Explore dynamic work environments in prominent areas
                like Sector 44, 90B Udyog Vihar, and DLF Cyber City. These
                locations offer excellent connectivity via metro and cabs.
              </p>
            </li>
            <li>
              <p>
                Noida: Choose from contemporary coworking spaces in Sector 1,
                Sector 2, and Sector 63. These centers are conveniently located
                near major business hubs and offer easy access to public
                transport.
              </p>
            </li>
            <li>
              <p>
                Mumbai: Immerse yourself in a collaborative atmosphere at
                centers in Andheri East, Vikhroli West, and Bandra-Kurla
                Complex. These locations are well-served by the Mumbai local
                train network, making them easily accessible.
              </p>
            </li>
            <li>
              <p>
                Pune: Discover inspiring workspaces in Yerwada and Baner. These
                centers are close to key IT parks and provide smooth
                connectivity through public transport.
              </p>
            </li>
            <li>
              <p>
                Hyderabad: Unleash your potential at centers situated in HITEC
                City, Kondapur, and Madhapur. These locations are hubs for
                innovation and offer excellent public transport options.
              </p>
            </li>
            <li>
              <p>
                Bangalore: Find your ideal work setting in J.P. Nagar, 7th Block
                Koramangala, and 8th Block Koramangala. These centers are
                strategically located in the heart of Bangalore's bustling
                startup ecosystem and provide easy access to public transport.
              </p>
            </li>
            <li>
              <p>
                Goa: Soak in the sunshine while working from a stunning
                coworking space in Panjim. This center offers a refreshing work
                environment with a relaxed vibe, yet remains well-connected.
              </p>
            </li>
          </ul>
          <br />
          <p>
            <strong>Why Book Through GoFloaters?</strong>
          </p>
          <p>
            Booking your workspace with GoFloaters offers unmatched advantages.
            GoFloaters guarantees you the lowest possible price, saving you time
            and money. The platform allows for easy discovery, booking, and
            secure online payment, streamlining the entire process. With
            GoFloaters, you can check instant availability for any 91Springboard
            space in real-time, eliminating the need for calls or emails. So
            ditch the hassle and book your dream workspace with 91Springboard
            through GoFloaters today!
          </p>
          <br />
        </div>
      )
    case "MyBranch":
      return (
        <div className="col-md-12">
          <p>
            MyBranch is a leader in the co-working and shared office network
            with the widest reach in India, covering 70+ locations spanning
            across east, west, north and south. Take your business Pan-India
            with our solutions galore that include Managed Office Space, Shared
            Office Space, Lite Office Space, Virtual Office Space and Meeting
            Rooms. Freelancers love our spaces for networking opportunities,
            startups innovate and ideate the next big thing using our top-notch
            facilities and established companies expand at ease leveraging our
            excellent services.
          </p>
          <p>
            <strong>Chennai</strong> - Our stunning workspace in Chennai with a
            full range of amenities is suitable for all kinds of working
            professionals - from solopreneurs and startup co-founders to branch
            officers and your whole business teams. Located at JVL Plaza,
            connectivity is a breeze.
          </p>
          <p>
            <strong>Mumbai</strong> - Located in Andheri, MyBranch’s co-working
            space in Mumbai promises a prestigious address for your business.
            Easy connectivity, best-in-class amenities plus a vibrant and lively
            space is a go-to place to turn professional dreams into reality.
          </p>
          <p>
            <strong>Noida</strong> - Fully-furnished offices, modern amenities
            and additional facilities such as private cabins, meeting room and
            training room makes our Noida office space at Chandra Heights an
            excellent option for your business to flourish.
          </p>
          <p>
            <strong>Pune</strong> - Our beautiful office space in Pune, equipped
            with a comprehensive array of facilities, caters to various types of
            professionals who are taking the city by storm through their
            innovation, ideas and creativity, contributing to its growth and
            success. Excellent train connectivity and only 8 km from airport
            makes our workspace your best choice for progress.
          </p>
          <p>
            <strong>Ahmedabad</strong> - Located in Ahmedabad, our impressive
            workspace features a wide range of amenities, making it ideal for
            all types of businesses—from BFSI, Telecommunication to media and
            multinational conglomerates. In addition, our workspace is one of
            the largest collaborative spaces in Gujarat, offering great
            visibility and growth opportunities.
          </p>
          <p>
            <strong>Indore</strong> - Our remarkable office environment in
            Indore, which boasts a full suite of amenities, IT support and
            meeting room, is perfect for diverse working professionals. It
            offers many networking opportunities for your business to exchange
            ideas and boost growth.
          </p>
          <p>
            <strong>Aizawl</strong> - Establishing our presence in the east, the
            shared offices and co-working spaces are maintained to high
            standards, equipped with air conditioning and all necessary
            furnishings. In the fast-moving world of work today, we work
            relentlessly to offer your business a space that promotes a
            professional atmosphere.
          </p>
          <p>
            <strong>Ambala</strong> - The shared office and co-working
            facilities at MyBranch’s Ambala office are well-maintained, equipped
            with air conditioning, and fully furnished. In today’s quick-paced
            work environment, it is advantageous for your business to have a
            space that exudes professionalism, and we offer exactly in our
            innovative spaces.
          </p>
          <p>
            <strong>Amritsar</strong> - In Amritsar, our exceptional workspace
            comes with a complete set of amenities, making it suitable for
            businesses of all sizes. It does not matter if you are an
            independent entrepreneurs, co-founder of multiple startups or a
            growing business with ever-expanding branch employees, our office
            offers effortless connectivity via air and rail routes.
          </p>
          <p>
            <strong>Dehradun</strong> - Our attractive office in Dehradun,
            featuring an extensive range of amenities is the best place to be
            for your work engagements. Essential services like IT support
            personnel, a welcoming receptionist, and branding for the company
            are all provided. Entrepreneurs can feel at ease knowing we offer
            both shared and managed office environments in Dehradun.
          </p>
          <br /> <br />
        </div>
      )
    default:
      return <div className="col-md-12"></div>
  }
}
